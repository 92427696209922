import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { Icon, IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import {Cancel, Category, Search} from "@mui/icons-material"
import { useNavigate } from "react-router-dom";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { getCountry } from "./Data/dataCategories";

import champion from "../image/champion.png";
import grandGold from "../image/grand-gold.png";
import gold from "../image/gold.png";
import silver from "../image/silver.png";
import bronze from "../image/bronze.png";

//import SearchBar from "material-ui-search-bar";

/* ------------------------------------------------- */
/* ------------------------------------------------- */

const awardsWeights: any = {
    'POSEBNO PRIZNANJE': 0,
    'ŠAMPION': 1,
    'VELIKA ZLATA MEDALJA': 2,
    'ZLATA MEDALJA ZA TRŽNO PONUDBO': 3,
    'ZLATA MEDALJA': 3,
    'SREBRNA MEDALJA ZA TRŽNO PONUDBO': 4,
    'SREBRNA MEDALJA': 4,
    'BRONASTA MEDALJA ZA TRŽNO PONUDBO': 5,
    'BRONASTA MEDALJA': 5
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },

}));
  
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#FAFAFA',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
      borderRight: "1px solid rgba(224, 224, 224, 1)",
      
    },
}));

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} className="tooltip" />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor:'#939393',
      color: 'white',
      boxShadow: theme.shadows[1],
      fontSize: 14,
      padding:'6px',
    },
  }));


interface Data {
    num: number;
    company: string;
    subcategory: string;
    name: string;
    country: string;
    prize: string;
}

function descendingComparator(a: any, b: any, orderBy: (data: any) => number | string) {
    if (orderBy(b) < orderBy(a)) {
        return -1;
    }
    if (orderBy(b) > orderBy(a)) {
        return 1;
    }
    return 0;
}

type Order = "asc" | "desc";

function getComparator(
    order: Order,
    orderBy: (data: any) => number | string
): (
    a: any,
    b: any
) => number {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
    array: T[],
    comparator: (a: T, b: T) => number
) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        //console.log("comparing", a, b)
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    label2?:string
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: "num",
        numeric: true,
        disablePadding: true,
        label: "#",
    },
    {
        id: "company",
        numeric: false,
        disablePadding: false,
        label: "Podjetje / Izvajalec",
    },
    {
        id: "subcategory",
        numeric: false,
        disablePadding: false,
        label: "Podkategorija",
        label2: "Razred",
    },
    {
        id: "name",
        numeric: false,
        disablePadding: false,
        label: "Ime izdelka",
    },
    {
        id: "country",
        numeric: false,
        disablePadding: false,
        label: "Država",
    },
    {
        id: "prize",
        numeric: false,
        disablePadding: false,
        label: "Nagrada",
    },
];

interface EnhancedTableProps {
    onRequestSort: (
        event: React.MouseEvent<unknown>,
        property: keyof Data
    ) => void;
    order: Order;
    orderBy: string;
    category: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const {
        order,
        orderBy,
        onRequestSort,
        category,
    } = props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={"left"}
                        padding={ "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {(headCell.label2 && category === "slo-oskar-za-embalazo") ? headCell.label2 : headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}


/*------------------------------------ ---------------*/
/* ------------------------------------------------- */

function awardPlaceholderIcon( sample:string){
    switch(sample) {
        case 'POSEBNO PRIZNANJE':
            //return 'posebno-priznanje';
            return ''
        case 'ŠAMPION':
            //return 'sampijon';
            return champion
        case 'VELIKA ZLATA MEDALJA':
            //return 'v-zlata-nagada';
            return grandGold
        case 'ZLATA MEDALJA ZA TRŽNO PONUDBO':
        case 'ZLATA MEDALJA':
            //return 'zlata-nagrada';
            return gold
        case 'SREBRNA MEDALJA ZA TRŽNO PONUDBO':
        case 'SREBRNA MEDALJA':
            //return 'srebrna-nagrada';
            return silver
        case 'BRONASTA MEDALJA ZA TRŽNO PONUDBO':
        case 'BRONASTA MEDALJA':
            return bronze
            //return 'bronasta-nagrada';
        default:
            return '';
    }
}


const TableSection = (props:any) => {

    let icons = [champion, grandGold, gold, silver, bronze];
    
    const [order, setOrder] = React.useState<Order>("asc");
    const [orderBy, setOrderBy] = React.useState<string>("company");
    const [selected, setSelected] = React.useState<readonly number[]>([]);
    
    const [data, setData] = React.useState<any[]>([]);
    let sumEl = props.data.length;
    const lang = "si"

    const navigate = useNavigate();

    React.useEffect(() => {
        setData(stableSort(props.data, getComparator(order, (data) => data.details.company)).map((val: any, index: number) => {
            val.serial = index +1 
            return val
        }))
    }, [props])


    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data
    ) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };


    const handleClick = (event: React.MouseEvent<unknown>, id: number, data:any) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: readonly number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
        console.log(id, data);

        navigate("/2024/"+data.category+"/" + id);
    };

    const isSelected = (id: number) => selected.indexOf(id) !== -1;

    const [searchQuery, setSearchQuery] = React.useState("");

    // Avoid a layout jump when reaching the last page with empty rows.

    const visibleRows = React.useMemo(
        () => {
            const makeList = () => {
                switch(orderBy) {
                    case "num":
                        return stableSort(data, getComparator(order, (data) => data.serial))
                    case "company":
                        return stableSort(data, getComparator(order, (data) => data.details.company))
                    case "subcategory":
                        return stableSort(data, getComparator(order, (data) => data.details.group[lang]))
                    case "name":
                        return stableSort(data, getComparator(order, (data) => data.details.title[lang]))
                    case "country":
                        return stableSort(data, getComparator(order, (data) => data.details.country[lang]))
                    case "prize":
                        // Replace Š with S, because Š in english unicode comes after the whole alphabet, which is wrong
                        return stableSort(data, getComparator(order, (data) => {
                            // data.details.title[lang].replace("Š", "S")
                            if(awardsWeights[data.details.title[lang]]) {
                                return awardsWeights[data.details.title[lang]]
                            }
                            return 6
                        }))
                }
                
                return [...data];
            }
            const out = makeList();
            if(searchQuery != "") {
                const query = searchQuery.toLowerCase()
                return out.filter((value: any) => {
                    return value.serial.toString().includes(query) || value.details.producer.toLowerCase().includes(query) || 
                        value.details.category[lang].toLowerCase().includes(query) || value.details.product[lang].toLowerCase().includes(query) || 
                        value.details.country[lang].toLowerCase().includes(query) || value.details.title[lang].toLowerCase().includes(query)
                })
            }
            return out
        },
        [data, order, orderBy, searchQuery]
    );

    return (
        <div className='searchTableAgra'>
            
            <OutlinedInput
                id="searchbar"
                fullWidth
                //color="#F49F21"
                size="small"
                style={{marginBottom:"20px"}}
                type="text"
                value={searchQuery}
                onChange={(event) => {
                    setSearchQuery(event.target.value)
                }}
                startAdornment={
                    <InputAdornment position="start">
                        <Icon component={Search}/>
                    </InputAdornment>
                }
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            onClick={() => setSearchQuery("")}
                        >
                            <Cancel/>
                        </IconButton>
                    </InputAdornment>
                }
            />

        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={"medium"}
                        stickyHeader aria-label="sticky table"
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            category={props.category}
                        />
                        <TableBody>
                            {visibleRows.map((row:any, index:any) => {
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                let classNameDependsOnAward = awardPlaceholderIcon(row.details.title[lang]);

                                return (
                                    <StyledTableRow
                                        hover
                                        onClick={(event) =>{
                                            if(row.category != "slo-oskar-za-embalazo"){
                                                handleClick(event, row.id,row)
                                            }}
                                            
                                        }
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.id}
                                        selected={isItemSelected}
                                        sx={{ cursor: "pointer" }}
                                    >
                                        <StyledTableCell sx={{width:"20px"}} className="m-hide">
                                            {row.serial}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            
                                            {row.details.producer.length > 35 ?
                                                <LightTooltip   title={row.details.producer} arrow>
                                                    <div>{row.details.producer}</div>
                                                </LightTooltip>
                                            : <div className="tooltip">{row.details.producer}</div>
                                            }
                                        </StyledTableCell>
                                        <StyledTableCell className="m-hide" align="left" >
                                           
                                            {row.details.group[lang].length > 35 ?
                                                <LightTooltip   title={row.details.group[lang]} arrow>
                                                    <div>{row.details.group[lang]}</div>
                                                </LightTooltip>
                                            : <div className="tooltip">{row.details.group[lang]}</div>
                                            }
                                          
                                        </StyledTableCell>
                                        <StyledTableCell align="left" >
                                            {row.details.product[lang].length > 35 ?
                                                <LightTooltip   title={row.details.product[lang]} arrow>
                                                    <div>{row.details.product[lang]}</div>
                                                </LightTooltip>
                                            : <div className="tooltip" >{row.details.product[lang]}</div>
                                            }
                                            
                                        </StyledTableCell>
                                        <StyledTableCell align="left" className="m-hide">
                                            <div>
                                                <div style={{marginTop:"10px"}}><img style={{marginBottom:"10px"}} alt={getCountry(row.details.country['si'])} width="24px" height="16px" src={ "https://purecatamphetamine.github.io/country-flag-icons/3x2/" + getCountry(row.details.country['si']) + ".svg" } /></div>
                                                <div style={{marginTop: "8px", marginLeft:"5px"}}>{row.details.country[lang]}</div>
                                            </div>
                                        </StyledTableCell>
                                        <StyledTableCell align="left"  >
                                            <div>
                                                {/*<p className={`icon-reward ${classNameDependsOnAward }`}></p>*/}
                                                <img
                                                    src={classNameDependsOnAward}
                                                    alt="icon"
                                                    className="icon"
                                                    width="16px" height="16px"
                                                    style={{marginTop:"2px"}}
                                                />

                                                {row.details.title[lang].length > 35 ?
                                                    <LightTooltip   title={row.details.title[lang].charAt(0) + row.details.title[lang].substring(1).toLowerCase()} arrow>
                                                        <div>{row.details.title[lang].charAt(0) + row.details.title[lang].substring(1).toLowerCase()}</div>
                                                    </LightTooltip>
                                                : <div className="tooltip" style={{marginTop: "00px", marginLeft:"5px"}}>{row.details.title[lang].charAt(0) + row.details.title[lang].substring(1).toLowerCase()}</div>
                                                }
                                            </div>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            </Box>

            <div className='table-num-el'>
                <p>Število elementov v tabeli: {sumEl}</p>
            </div>
        </div>
    )
}

export default TableSection
